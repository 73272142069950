// import("admin-lte/dist/css/adminlte.min.css")
import("admin-lte/dist/js/adminlte")
import("admin-lte/plugins/bootstrap/js/bootstrap")
import ("admin-lte/plugins/fontawesome-free/css/all.css")
import ("admin-lte/plugins/jquery/jquery")
import ("bootstrap-vue/dist/bootstrap-vue.css");
// import ("admin-lte/plugins/select2/css/select2.css")
// import ("admin-lte/plugins/select2/js/select2")
// import ("admin-lte/plugins/fullcalendar/main.css")
// import ("admin-lte/plugins/fullcalendar/main")

